import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";

import { Loader } from "../../atoms/Loader";
import { ProductTile } from "../../molecules";
import { paths } from "../../../paths";
import { Button } from "../../Button";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "../../../intl";
import styles from "./index.module.scss";

export const ProductList = ({
  products,
  canLoadMore = false,
  loading = false,
  onLoadMore = () => null,
}) => (
  <>
    <div className={styles["product-list"]}>
      {products.map((product) => {
        const { slug, name } = product;
        return (
          slug &&
          name && (
            <Link href={generatePath(paths.product, { slug })} key={slug} prefetch={false}>
              <a>
                <ProductTile product={product} />
              </a>
            </Link>
          )
        );
      })}
    </div>
    <div className={styles.action}>
      {loading ? (
        <Loader />
      ) : (
        canLoadMore && (
          <Button color="secondary" onClick={onLoadMore}>
            <FormattedMessage {...commonMessages.moreProductsAction}/>
          </Button>
        )
      )}
    </div>
  </>
);
