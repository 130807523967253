

export function maybe(exp, d) {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
}

export function filterNotEmptyArrayItems(
  value
) {
  return value !== null && value !== undefined;
}

export function pick(obj, keys) {
  return { ...keys.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}) };
}

export function chunk(array, size = 1) {
  const arrayChunks = [];
  for (let i = 0; i < array.length; i += size) {
    const arrayChunk = array.slice(i, i + size);
    arrayChunks.push(arrayChunk);
  }
  return arrayChunks;
}
