import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "../../../intl";
import styles from "./index.module.scss"

export const Loader = ({ fullScreen, color }) => {
  return (
    <div className={styles.loader}>
      <FormattedMessage {...commonMessages.loading}/>
    </div>
  );
};
