import React from "react";

import { PlaceholderImage } from "../../atoms/PlaceholderImage";
import { maybe } from "../../utils/misc";

import { CachedImage } from "../CachedImage";

export const Thumbnail = ({
  source,
  children,
  ...props
}) => {
  const { thumbnail, thumbnail2x } = source;

  if (!thumbnail && !thumbnail2x) {
    return <PlaceholderImage />;
  }

  return (
    <CachedImage
      {...props}
      url={maybe(() => (thumbnail || {}).url)}
      url2x={maybe(() => (thumbnail2x || {}).url)}
      alt={maybe(() => ((thumbnail || {}).alt ? (thumbnail || {}).alt : ""), "")}
    >
      {children}
    </CachedImage>
  );
};
