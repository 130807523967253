import React from "react";


import NoPhoto from "../../../images/no-photo.svg";

export const PlaceholderImage = ({
  alt = "placeholder",
}) => {
  return <img src={NoPhoto} alt={alt} />;
};
