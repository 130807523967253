import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import {
  attributeFragment,
  featuredProductsFragment,
  menuItemFragment,
} from "./fragments";

export const useTypedQuery = (query, options) => {
  const queryResult = useQuery(query, options);

  const loadMore = (mergeFn, endCursor) => {
    queryResult.fetchMore({
      query,
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }
        return mergeFn(previousResults, fetchMoreResult);
      },
      variables: { ...options?.variables, after: endCursor },
    });
  };

  return { loadMore, ...queryResult };
};

export const featuredProductsQuery = gql`
  ${featuredProductsFragment}
  query FeaturedProductsQuery($channel: String!) {
    ...FeaturedProducts
  }
`;

export const shopAttributesQuery = gql`
  ${attributeFragment}
  query ShopAttributesQuery(
    $channel: String!
    $collectionId: ID
    $categoryId: ID
  ) {
    attributes(
      filter: {
        channel: $channel
        inCollection: $collectionId
        inCategory: $categoryId
        filterableInStorefront: true
      }
      first: 100
    ) {
      edges {
        node {
          ...Attribute
        }
      }
    }
  }
`;

export const shopMenusQuery = gql`
  ${menuItemFragment}
  query ShopMenusQuery(
    $channel: String!
    $footerSlug: String!
    $mainMenuSlug: String!
  ) {
    footer: menu(channel: $channel, slug: $footerSlug) {
      id
      items {
        ...MenuItem
        children {
          ...MenuItem
        }
      }
    }
    mainMenu: menu(channel: $channel, slug: $mainMenuSlug) {
      items {
        ...MenuItem
        children {
          ...MenuItem
          children {
            ...MenuItem
          }
        }
      }
    }
  }
`;
