import React from "react";

import styles from "./scss/index.module.scss";

export const Button = ({
  className = "",
  children,
  testingContext,
  secondary,
  btnRef,
  size,
  type,
  ...otherProps
}) => (
  <button
    data-test={testingContext}
    className={`${styles.button} ${secondary ? "secondary" : ""} ${
      size || "medium"
    } ${className}`}
    ref={btnRef}
    type={type}
    {...otherProps}
  >
    <span>{children}</span>
  </button>
);
