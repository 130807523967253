import React from "react";

import { TaxedMoney } from "../../atoms/TaxedMoney";
import { Thumbnail } from "../Thumbnail";

import styles from "./scss/index.module.scss";

export const ProductTile = ({ product }) => {
  const price =
    product.pricing &&
    product.pricing.priceRange &&
    product.pricing.priceRange.start
      ? product.pricing.priceRange.start
      : undefined;

  return (
    <div className={styles["product-tile"]}>
      <div className={styles["image-box"]}>
        <Thumbnail source={product} />
      </div>

      <h4 className={styles["name"]}>{product.name}</h4>
      <p className={styles["price"]}>
        <TaxedMoney taxedMoney={price} />
      </p>
    </div>
  );
};
