import React from "react";
import {currency, currencyMult} from "../../../constants";
export const Money = ({
  money,
  defaultValue,
  ...props
}) => {
  if (!money) {
    return <span {...props}>{defaultValue}</span>;
  }
  const amount = currencyMult && currencyMult !== 1 ? Math.round(parseFloat(money.amount) * (currencyMult || 1)) : money.amount;
  const price = (amount.toLocaleString(process.env.LANGUAGE_LOCALE, {currency: currency || money.currency,style: "currency"})) || ""

  return (
    <span {...props}>
      {price}
    </span>
  );
};

Money.displayName = "Money";
export default Money;
